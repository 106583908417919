import React from "react"

import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

import image1 from "./images/image-1.jpg"
import image2 from "./images/image-2.jpg"
import image3 from "./images/image-3.jpg"
import image4 from "./images/image-4.jpg"
import image5 from "./images/image-5.jpg"
import image6 from "./images/image-6.jpg"
import image7 from "./images/image-7.jpg"
import image8 from "./images/image-8.jpg"

const Page = () => {
  return (
    <DriveLayout title="Greenmarket Car Park Office">
      <Hero title="Greenmarket Car Park Office" />
      <Container size="medium">
        <Stack spacing="XLarge">
          <EmberCard title="Introduction">
            <EmberCardSection>
              <Stack direction="column">
                <Text>
                  Drivers have access to the office space on the ground floor of
                  the Greenmarket multi-storey car park. This provides nearby
                  break facilities when using the Greenmarket charger. The
                  office is located at the entrance to the car park and sign
                  posted as reception.
                </Text>
                <Text>
                  The office contains a kettle, fridge and radio for communal
                  use. The fridge is located behind the door. The first aid kit
                  is located on top of the filing cabinet opposite the door.
                </Text>
                <Text>
                  If you are using the facilities please leave them the way
                  you’d hope to find them. Clean, tidy and secure.
                </Text>
                <img src={image1} />
                <img src={image2} />
                <Text>
                  The disabled toilet and kitchen are located on the other side
                  of the car park entrance. The doors are double locked so you
                  may have to turn the key more than once to open it. The toilet
                  is the door closest to the barrier and the kitchen is closest
                  to the exit.
                </Text>
                <Text>
                  Please do not move or attempt to use any kitchen appliances
                  sitting on the floor as these haven’t been PAT tested for use.
                </Text>
                <img src={image3} />
                <img src={image4} />
                <img src={image5} />
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Access">
            <EmberCardSection>
              <Stack direction="column">
                <Text>
                  The door has a key pad with a three-digit code for entry. The
                  code will be given to you upon completion of the council’s
                  fire safety induction. If you need to complete a council
                  induction please contact hub support or ops in your room.
                </Text>
                <Text>
                  Note that the intruder alarm for the office door is sensitive,
                  therefore please make sure the door does not slam when
                  entering or leaving the office.
                </Text>
                <Text>
                  The office is also sometimes used by council staff and parking
                  attendants so please do not leave valuable items unattended in
                  the office.
                </Text>
                <Text>
                  On the wall beside the whiteboard there is a key box
                  containing the keys to access the toilet and kitchen areas.
                  These are labelled and will be sitting in numbers 8 and 9.
                  There is only one key for each so please make sure you return
                  them immediately after use. If you take these away with you it
                  then stops everyone else from accessing these facilities.
                </Text>
                <img src={image6} />
              </Stack>
            </EmberCardSection>
          </EmberCard>
          <EmberCard title="Fire Safety">
            <EmberCardSection>
              <Stack direction="column">
                <Text>
                  When using the office all drivers are required to sign in and
                  out in the fire register. This is a red folder which sits on
                  the window sill.
                </Text>
                <img src={image7} />
                <Text>
                  Fire extinguishers are located under the whiteboard in the
                  office and there is a fire blanket located on the wall beside
                  the window in the kitchen area. The fire panel and nearest
                  fire call point are located on the wall between the doors for
                  the toilet and kitchen.
                </Text>
                <Text>
                  There are other fire extinguishers located throughout the car
                  park, in red casing. The nearest one to the office is on the
                  walkway across to the pay stations to the left of the toilet
                  area.
                </Text>
                <Text>
                  The usual fire escape route would be to leave through the car
                  park entrance. If for some reason that isn’t possible the next
                  closest exit is the double doors at the pay stations. This is
                  directly across from the office door along the wall to the
                  side of the toilet.
                </Text>
                <Text>
                  The assembly/muster point during an evacuation is across the
                  street, on the grass by the entrance for the the Science
                  Centre Car Park.
                </Text>
                <img src={image8} />
                <Text>
                  The council test the fire alarm every Sunday at 1pm. If the
                  fire alarm lasts more than 20 seconds during this time you
                  should evacuate the building.
                </Text>
              </Stack>
            </EmberCardSection>
          </EmberCard>
        </Stack>
      </Container>
    </DriveLayout>
  )
}

export default Page
